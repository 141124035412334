var TC = 'TARJETA DE CRÉDITO'
var TD = 'TARJETA DE DÉBITO'
var VC = 'VOUCHER'
var EF = 'EFECTIVO'
var CC = 'CTA. CTE.'
var TR = 'TRANSFERENCIA'
var MP = 'MERCADO PAGO'


import store from '@/store/index'
import VentasApi from '@/api/venta/venta'

import { imprimirComprobante } from '@/services/comprobante.js'
import { getDetallesParaServer } from "@/services/detallesComprobante/serviceDetalles";
import { controlFormasDePago } from "@/services/ventas/registrarVenta.js"



var add_forma_pago = (context, descripcion, monto) => {
  console.log(monto);
  context.commit('AGREGAR_FORMA_DE_PAGO', { descripcion, monto })
  return
}
var set_monto_credito = (context, monto) => {
  context.commit('SET_MONTO_CREDITO', monto)
  return
}
var set_monto_debito = (context, monto) => {

  context.commit('SET_MONTO_DEBITO', monto)
  return
}
var es_forma_de_pago = (context, descripcion) => {

  return context.getters['get_forma_de_pago_seleccionada'](descripcion)
}
var es_efectivo = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](EF)
}
var es_debito = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](TD)
}
var es_credito = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](TC)
}
var es_transferencia = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](TR)
}
var es_voucher = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](VC)
}
var es_mercado_pago = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](MP)
}
var es_cuenta_corriente = (context) => {

  return context.getters['get_forma_de_pago_seleccionada'](CC)
}



export function agregar_monto_forma_de_pago(context, data) {
  var total = context.getters['get_total']
  var subtotal = context.getters['get_subtotal_con_impuestos']

  var cantidad_formas = context.state.formasDePagoSeleccionadas.length
  var monto = parseFloat(parseFloat(data.monto).toFixed(2))
  if (data.descripcion == EF) {
    // context.commit('SET_MONTO_EFECTIVO', Math.abs(data.monto))
    context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: EF, monto: monto })


    if (cantidad_formas == 2) {
      var faltante = subtotal - monto
      var totalFaltante = faltante > 0 ? faltante : 0
      if (es_credito(context)) {
        set_monto_credito(context, totalFaltante)
        if (context.state.porcentajeRecargoCredito) {
          totalFaltante = parseFloat((faltante * (1 + context.state.porcentajeRecargoCredito / 100)).toFixed(2));

        }
        //context.commit('SET_MONTO_FP_CREDITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: totalFaltante > 0 ? totalFaltante : 0 })
        return
      } else if (es_debito(context)) {
        if (context.state.descuento) {
          var porcentaje = context.state.descuento
          var descuento_del_subtotal = parseFloat((subtotal * (porcentaje / 100)).toFixed(2));


          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante - descuento_del_subtotal : 0 })
          return
        }
        // context.commit('SET_MONTO_FP_DEBITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante : 0 })

        return
      } else if (es_voucher(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: VC, monto: totalFaltante })

        return
      } else if (es_cuenta_corriente(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: CC, monto: totalFaltante })

        return
      } else if (es_mercado_pago(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: totalFaltante })

        return
      }
      else if (es_transferencia(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: totalFaltante })
        return
      }
    }
  }
  if (data.descripcion == VC) {
    // context.commit('SET_MONTO_VOUCHER', data.monto)
    context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: VC, monto: monto })

    if (cantidad_formas == 2) {
      var faltante = subtotal - monto

      var totalFaltante = faltante > 0 ? faltante : 0

      if (es_debito(context)) {
        set_monto_debito(context, totalFaltante)
        return

      } else if (es_efectivo(context)) {
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: EF, monto: totalFaltante })
        return
      } else if (es_cuenta_corriente(context)) {
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: CC, monto: totalFaltante })
        return
      }
    }
  }
  if (data.descripcion == TC) {
    console.log("Es Tarjeta de credito");
    if (cantidad_formas == 1) {
      console.log(total);
      set_monto_credito(context, total)
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: total })
      return
    }
  }
  if (data.descripcion == TD) {
    if (cantidad_formas == 1) {
      set_monto_debito(context, total)
      context.commit('SET_MONTO_DEBITO', total)
      return
    }
  }
  if (data.descripcion == CC) {
    context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: CC, monto: monto })
    if (cantidad_formas == 2) {
      var faltante = subtotal - monto
      var totalFaltante = faltante > 0 ? faltante : 0
      if (context.getters['get_forma_de_pago_seleccionada'](TC)) {
        context.commit('SET_MONTO_CREDITO', totalFaltante)
        if (context.state.porcentajeRecargoCredito) {
          totalFaltante = parseFloat((faltante * (1 + context.state.porcentajeRecargoCredito / 100)).toFixed(2));

        }
        //context.commit('SET_MONTO_FP_CREDITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: totalFaltante > 0 ? totalFaltante : 0 })
        return
      } else if (context.getters['get_forma_de_pago_seleccionada'](TD)) {
        if (context.state.descuento) {
          var porcentaje = context.state.descuento
          var descuento_del_subtotal = parseFloat((subtotal * (porcentaje / 100)).toFixed(2));

          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante - descuento_del_subtotal : 0 })

          return
        }
        // context.commit('SET_MONTO_FP_DEBITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante : 0 })

        return
      } else if (context.getters['get_forma_de_pago_seleccionada'](VC)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: VC, monto: totalFaltante })

        return
      } else if (context.getters['get_forma_de_pago_seleccionada'](EF)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: EF, monto: totalFaltante })

        return
      }
    }
  }
  //
  if (data.descripcion == MP) {
    context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: monto })

    if (cantidad_formas == 2) {
      var faltante = subtotal - monto
      var totalFaltante = faltante > 0 ? faltante : 0
      if (es_credito(context)) {
        set_monto_credito(context, totalFaltante)
        if (context.state.porcentajeRecargoCredito) {
          totalFaltante = parseFloat((faltante * (1 + context.state.porcentajeRecargoCredito / 100)).toFixed(2));

        }
        //context.commit('SET_MONTO_FP_CREDITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: totalFaltante > 0 ? totalFaltante : 0 })
        return
      } else if (es_debito(context)) {
        if (context.state.descuento) {
          var porcentaje = context.state.descuento
          var descuento_del_subtotal = parseFloat((context.state.subtotal * (porcentaje / 100)).toFixed(2));


          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante - descuento_del_subtotal : 0 })
          return
        }
        // context.commit('SET_MONTO_FP_DEBITO', totalFaltante > 0 ? totalFaltante : 0)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: totalFaltante > 0 ? totalFaltante : 0 })

        return
      } else if (es_voucher(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: VC, monto: totalFaltante })

        return
      } else if (es_cuenta_corriente(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: CC, monto: totalFaltante })

        return
      } else if (es_efectivo(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: EF, monto: totalFaltante })

        return
      }
      else if (es_transferencia(context)) {
        // context.commit('SET_MONTO_VOUCHER', totalFaltante)
        context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: totalFaltante })
        return
      }
    }
  }
  //
  if (data.descripcion == TR) {
    context.commit('SET_MONTO_TRANSFERENCIA', total)
    if (cantidad_formas == 2) {

    }
  }
}
export function agregar_quitar_forma_de_pago(context, descripcion) {

  var array = context.state.formasDePagoSeleccionadas
  var subtotal = parseFloat(context.getters['get_subtotal_con_impuestos'])
  var esNueva = true
  var index = array.findIndex(el => el.nombre == descripcion)
  console.log("Estoy buscando si la forma de pago esta en formasDePagoSeleccionadas ... ");
  if (index > -1) {
    console.log("Se encontró la forma de pago dentro de formasDePagoSeleccionadas");
    esNueva = false;
    context.commit('QUITAR_FORMA_DE_PAGO', index)


  }
  var cantidad_formas = context.state.formasDePagoSeleccionadas.length
  console.log("cantidad_formas", cantidad_formas);
  var total = context.getters['get_total']
  console.log("TOTAL: ", total);
  if (!esNueva) {
    if (descripcion == EF) {
      if (cantidad_formas == 1) {
        if (es_debito(context)) {
          set_monto_debito(context, total)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: total })
          return
        }
        if (es_credito(context)) {
          set_monto_credito(context, total)
          context.commit('SET_MONTO_CREDITO', total)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: total })
          return
        }
        if (es_mercado_pago(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: total })
        }
        if (es_transferencia(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: total })
        }

      }
    }
    if (descripcion == TC) {
      set_monto_credito(context, 0)
      context.commit('SET_PORCENTAJE_RECARGO_CREDITO', null)
      if (cantidad_formas == 1) {
        console.log("Quitando TC y la fp que quedo seleccionada es:");
        if (es_debito(context)) {
          console.log("debito");
          set_monto_debito(context, total)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: total })
        }

        if (es_transferencia(context)) {
          console.log("transfere");

          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: total })
        }

        if (es_mercado_pago(context)) {
          console.log("merca do pago");

          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: total })
        }

      }
      return
    }
    if (descripcion == TD) {
      set_monto_debito(context, 0)
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: 0 })
      if (cantidad_formas == 1) {
        if (es_credito(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: total })
          set_monto_credito(context, total)
          return
        }
        if (es_transferencia(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: total })
        }
        if (es_mercado_pago(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: total })
        }
      }
    }
    if (descripcion == VC) {
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: VC, monto: null })
      return
    }
    if (descripcion == CC) {
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: CC, monto: null })
      return
    }
  }
  if (esNueva) {
    console.log("Es nueva forma de pago");
    var faltante = context.getters['faltante']
    if (context.state.formasDePagoSeleccionadas.length == 2) {
      return
    }
    // AGREGA MERCADO PAGO
    if (descripcion == MP) {
      if (cantidad_formas == 1) {
        add_forma_pago(context, MP, faltante)
        // if (es_efectivo(context)) {
        //   if (faltante > 0) {
        //     add_forma_pago(context, TR, faltante)
        //     return
        //   }
        // }
        // if (es_debito(context)) {
        //   if (faltante > 0) {
        //     add_forma_pago(context, TR, faltante)
        //     return
        //   }
        // }
        // if (es_credito(context)) {
        //   if (faltante > 0) {
        //     add_forma_pago(context, TR, faltante)
        //     return
        //   }
        // }
        // if (es_cuenta_corriente(context)) {
        //   if (faltante > 0) {
        //     add_forma_pago(context, TR, faltante)
        //     return
        //   }
        // }
        // if (es_credito(context)) {
        //   if (faltante > 0) {
        //     add_forma_pago(context, TR, faltante)
        //     return
        //   }
        // }
        return
      }
      add_forma_pago(context, MP, faltante)
    }
    // AGREGA TRANSFERENCIA

    if (descripcion == TR) {
      console.log("Se agrega TR");
      if (cantidad_formas == 1) {
        if (es_efectivo(context)) {
          if (faltante > 0) {
            add_forma_pago(context, TR, faltante)
            return
          }
        }
      }
      add_forma_pago(context, TR, faltante)
    }
    // AGREGA EFECTIVO
    if (descripcion == EF) {
      context.commit('AGREGAR_FORMA_DE_PAGO', { descripcion })
      if (cantidad_formas == 1) {
        if (es_debito(context)) {
          set_monto_debito(context, 0)
          // context.commit('SET_MONTO_FP_DEBITO', 0)
          add_forma_pago(context, descripcion)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: 0 })
          return
        }
        if (es_credito(context)) {
          set_monto_credito(context, 0)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: 0 })
          return
        }
        if (es_mercado_pago(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: MP, monto: 0 })
          return
        }
        if (es_transferencia(context)) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TR, monto: 0 })
          return
        }
        return
      }
    }
    // AGREGA TARJETA CRÉDITO
    if (descripcion == TC) {
      console.log("Es tarjeta de credito ");
      if (cantidad_formas == 1) {
        if (es_debito(context)) {
          //TODO: ¿Que debería hacer si agrego TC y existia TD ?
          add_forma_pago(context, descripcion, null)
          set_monto_credito(context, 0)
          return
        }
        if (es_efectivo(context)) {

          if (faltante > 0) {
            add_forma_pago(context, descripcion, faltante)
            set_monto_credito(context, faltante)
            return
          }
        }
      }
      console.log(subtotal);
      console.log(faltante);
      add_forma_pago(context, descripcion, faltante)
      console.log(subtotal);
      set_monto_credito(context, subtotal)

    }
    // AGREGA TARJETA DEBITO
    if (descripcion == TD) {
      if (cantidad_formas == 1) {
        if (es_credito(context)) {
          //TODO: Que debería hacer si agrego FP TD y existia TC?
          add_forma_pago(context, descripcion, null)
          set_monto_debito(context, 0)
          return
        }
        if (es_efectivo(context)) {
          if (faltante > 0) {
            add_forma_pago(context, descripcion, faltante)
            set_monto_debito(context, faltante)
            return
          }
        }
      }
      add_forma_pago(context, descripcion, faltante)
      set_monto_debito(context, subtotal)
      return
    }
    // AGREGA VOUCHER
    if (descripcion == VC) {
      add_forma_pago(context, descripcion, faltante)
      return
    }
    // AGREGA CTA CTE
    if (descripcion == CC) {
      add_forma_pago(context, CC, 0)
      if (cantidad_formas == 1) {
        if (es_debito(context)) {
          set_monto_debito(context, 0)
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: 0 })
          return
        }
        if (es_credito) {
          context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: 0 })
          set_monto_credito(context, 0)

          return
        }
        return
      }

    }
  }
}
export function aplicar_recargo_credito(context) {
  context.commit('SET_DESCUENTO', null)

  var porcentajeRecargo = context.state.porcentajeRecargoCredito
  var monto_credito = context.state.montoCredito
  var monto_con_recargo = parseFloat((monto_credito * (1 + porcentajeRecargo / 100)).toFixed(2));

  context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: monto_con_recargo })
}
export function quitar_recargo_credito(context) {
  var porcentaje = context.state.porcentajeRecargoCredito
  var monto_credito = context.state.montoCredito
  var monto_sin_procentaje_recargo_credito = monto_credito / (1 + porcentaje / 100)
  context.commit('SET_PORCENTAJE_RECARGO_CREDITO', null)
  //context.commit('SET_MONTO_FP_CREDITO', monto_sin_procentaje_recargo_credito)
  context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: monto_sin_procentaje_recargo_credito })
}
export function aplicar_descuento(context) {
  // Se aplica descuento únicamente cuando las FP (Formas de pago) EF o TD.
  var porcentaje = context.state.descuento
  var descuento_del_subtotal = Math.round(context.state.subtotal * (porcentaje / 100))
  //Si existe, se le asigna el descuento al Débito
  if (context.getters['get_forma_de_pago_seleccionada'](TD)) {
    if (context.getters['get_monto_forma_de_pago_seleccionada'](TD) > descuento_del_subtotal) {

      var monto_con_descuento = Math.round(context.getters['get_monto_forma_de_pago_seleccionada'](TD) - descuento_del_subtotal)
      // context.commit('SET_MONTO_FP_DEBITO', monto_con_descuento)
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: monto_con_descuento })
    } else {
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: 0 })

      // context.commit('SET_MONTO_FP_DEBITO', 0)

    }
  }
}
export function quitar_descuento(context) {
  var subtotal = parseFloat(context.getters['get_subtotal_con_impuestos'])
  console.log(subtotal);

  var montoEfectivo = context.getters['get_monto_forma_de_pago_seleccionada'](EF)
  var porcentaje = context.state.descuento
  console.log(context.getters['get_forma_de_pago_seleccionada'](TD));
  if (context.getters['get_forma_de_pago_seleccionada'](TD)) {
    if (montoEfectivo) {
      var faltante = subtotal - montoEfectivo
      var descuento_del_subtotal = parseFloat((subtotal * (porcentaje / 100)).toFixed(2));

      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: monto_con_descuento })

      let result = faltante + descuento_del_subtotal
      context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TD, monto: result })

      return
    }
  }
  context.commit('SET_DESCUENTO', null)
}
export function reset_recargo_descuento_credito(context) {
  context.commit('SET_PORCENTAJE_RECARGO_CREDITO', null)
  context.commit('SET_MONTO_CREDITO_SIN_RECARGO', 0)
  //context.commit('SET_MONTO_FP_CREDITO', 0)
  context.commit('SET_MONTO_FORMA_DE_PAGO', { nombre: TC, monto: 0 })
  context.commit('SET_DESCUENTO', 0)
}

export function registrar_venta(context, payload) {
  const {
    pedido_id,
    detalles,
    descuento = 0,
    motivoDescuento,
    recargo = 0,
    pagoCliente,
    esParaFacturar,
    esPrecioVentaManual

  } = payload;

  const cliente = context.state['clienteVenta'] ? context.state['clienteVenta'].id : 0
  const direccion = context.state['clienteDireccion'] ? context.state['clienteDireccion'].id : 0
  const esParaEnviar = context.state['esParaEnviar']
  const detalles_formato_server = getDetallesParaServer(detalles)

  var formData = {
    detalle_venta: detalles_formato_server,
    paraEnviar: esParaEnviar,
    cliente: cliente,
    direccion: direccion,
    pedido: pedido_id,
    formaDePago: controlFormasDePago(),
    descuento: descuento ? descuento : 0,
    motivoDescuento: motivoDescuento,
    recargo: recargo ? recargo : 0,
    pagaCon: pagoCliente,
    esParaFacturar: esParaFacturar,
    es_precio_venta_manual: esPrecioVentaManual,
    documento: esParaFacturar ? 'FC' : 'NV',
    esPedidoYa: store.state.remitos.esPedidoYa


  }
  return new Promise((resolve, reject) => {
    VentasApi.create(formData).then(response => {
      resolve(response);
      context.commit('RESET_CLIENTE_VENTA')
      store.dispatch('notificaciones/fetch_notificaciones', 1)
      var menupedidocruachan = store.state.sucursales.configuracion.menuVentaCruachan
      if (menupedidocruachan) {
        return
      }

      imprimirComprobante(response.data.id).then(response => {
        console.log("OK", response);
      }).catch(e => {
        console.log(e);
      })

    }, error => {

      reject(error);
    })

  })



}

