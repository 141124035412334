
export default [
    {
      path: '/contabilidad',
      name: 'contabilidad',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadHome.vue'),
      children: [
        {
          path: 'formas-de-pago',
          name: 'contabilidadFormasDePago',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadFormasDePago.vue'),
      
        },
        {
          path: 'formas-de-pago',
          name: 'contabilidadFormasDePagoRegistrar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadFormasDePagoRegistrar.vue'),
      
        },
        {
          path: 'cuentas-por-defecto',
          name: 'contabilidadCuentasDefecto',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadCuentasDefecto.vue'),
      
        },
        
        {
          path: 'plancuentas',
          name: 'plancuentas',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadPlanDeCuentas.vue'),
      
        },
        {
          path: 'rubroventa',
          name: 'rubroVenta',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroVenta.vue'),
      
        },
        {
          path: 'rubroventa/registrar',
          name: 'rubroVentaRegistrar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroVentaRegistrar.vue'),
      
        },
        {
          path: 'rubroventa/editar',
          name: 'rubroVentaUpdate',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroVentaUpdate.vue'),
      
        },
        {
          path: 'rubrocompra',
          name: 'rubroCompra',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroCompra.vue'),
      
        },
        {
          path: 'asiento/mantenimiento',
          name: 'asientoMantenimiento',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/manenimiento/MantenimientoAsientoView.vue'),
      
        },
        {
          path: 'asiento/mantenimiento/registrar',
          name: 'asientoMantenimientoRegistrar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/manenimiento/MantenimientoAsientoRegistrarView.vue'),
        },
        {
          path: 'asiento/mantenimiento/editar',
          name: 'asientoMantenimientoUpdate',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/manenimiento/MantenimientoAsientoUpdateView.vue'),
      
        },
        {
          path: 'asientotipo',
          name: 'asientoTipo',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/asientotipo/AsientoTipoView.vue'),
      
        },
        {
          path: 'asientotipo/registrar',
          name: 'asientoTipoRegistrar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/asientotipo/AsientoTipoRegistrarView.vue'),
        },
        {
          path: 'asientotipo/editar',
          name: 'asientoTipoUpdate',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/asiento/asientotipo/AsientoTipoUpdateView.vue'),
      
        },
        
        
        {
          path: 'rubrocompra/registrar',
          name: 'rubroCompraRegistrar',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroCompraRegistrar.vue'),
      
        },
        {
          path: 'rubrocompra/editar',
          name: 'rubroCompraUpdate',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/contabilidad/ContabilidadRubroCompraUpdate.vue'),
      
        },
        
      ],
      meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }
  
    },
  
    

    
  
  ]