export default [
  {
    path: '/ventas',
    name: 'ventas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/ventas.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireVentaConPedido: false }

  },
  {
    path: '/ventas/conusltar',
    name: 'consultarVenta',
    props: { tipoComprobante: 'ventas' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/VentaConsultar.vue'),



    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/ventas/conusltar/:id',
    name: 'detalleVenta',
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/venta.consultar.detalle.vue'),
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true, requireVentaConPedido: false }


  },
  {
    path: '/ventas/nuevo',
    name: 'nuevaVenta',
    props: { tipoComprobante: 'ventas' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/venta.registrar.vue'),



    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },

  
  {
    path: '/ventas/nuevo/v2',
    name: 'nuevaVentav2',
    props: { tipoComprobante: 'ventas' },
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/VentaContable.vue'),



    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/ventas/pedidos',
    name: 'pedidoVenta',
    props: { tipoComprobante: 'ventas' },
    // beforeEnter: (to, from, next) => {
    //   const permissionVentaConPedido = store.state.sucursales.configuracion.ventaConPedido
    //   if (permissionVentaConPedido) {
    //     next()
    //   }


    // },
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/pedidos/pedidos.venta.vue'),



    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },

  {
    path: '/ventas/pedidos/nuevo',
    name: 'nuevoPedidoVenta',
    props: { tipoComprobante: 'ventas' },
    // beforeEnter: (to, from, next) => {
    //   const permissionVentaConPedido = store.state.sucursales.configuracion.ventaConPedido
    //   if (permissionVentaConPedido) {
    //     next()
    //   }


    // },

    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/pedidos/pedido.venta.registrar.vue'),




    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }


  },
  {
    path: '/ventas/pedidos/actualizar/:id',
    name: 'ActualizarPedidoVenta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/venta/pedidos/pedido.venta.actualizar.vue'),
    // beforeEnter: (to, from, next) => {
    //   const permissionVentaConPedido = store.state.sucursales.configuracion.ventaConPedido
    //   if (permissionVentaConPedido) {
    //     next()
    //   }


    // },
    meta: { requiresAuth: true, requireArqueoCaja: true, requireCaja: true, requireSucursal: true }

  },
]