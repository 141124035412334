import state from './state'
import * as mutations from './mutations'
import * as actions from './actions'

const getters = {
  get_forma_de_pago_seleccionada: (state) => (fp) => {
    var array = state.formasDePagoSeleccionadas
    for (let index = 0; index < array.length; index++) {
      if (array[index].nombre == fp) {
        return array[index]
      }
    }
    return null
  },
  get_nombre_forma_de_pago_seleccionada: (state) => (fp) => {
    var array = state.formasDePagoSeleccionadas
    for (let index = 0; index < array.length; index++) {
      if (array[index].nombre == fp) {
        return fp
      }
    }
    return null
  },

  get_monto_forma_de_pago_seleccionada: (state) => (fp) => {
    var array = state.formasDePagoSeleccionadas

    for (let index = 0; index < array.length; index++) {
      if (array[index].nombre == fp) {
        console.log(array[index].monto);
        return array[index].monto
      }
    }
    return null
  },
  totalPagoCliente(state) {
    var total = 0
    var array = state.formasDePagoSeleccionadas
    for (let index = 0; index < array.length; index++) {
      var monto = parseFloat(array[index].monto)
      if (monto) {

        total += monto
      }

    }
    return total
  },
  totalDebitoEfectivo(state) {
    var total = 0
    var array = state.formasDePagoSeleccionadas
    for (let index = 0; index < array.length; index++) {
      if (array[index].nombre == 'EFECTIVO' || 'TARJETA DE DÉBITO') {

        var monto = parseFloat(array[index].monto)
        if (monto) {

          total += monto
        }
      }

    }
    return total
  },
  totalCredito(state) {
    var total = 0
    var array = state.formasDePagoSeleccionadas
    for (let index = 0; index < array.length; index++) {
      if (array[index].nombre == 'TARJETA DE CRÉDITO') {
        console.log(array[index].monto);
        var monto = parseFloat(array[index].monto)
        if (monto) {
          total += monto
        }
      }
    }
    console.log(total);
    return total
  },

  faltante: (state, getters) => {
    // Calcular la diferencia
    var diferencia = getters.get_total - getters.totalPagoCliente;

    // Redondear la diferencia a dos decimales
    var faltante = parseFloat(diferencia.toFixed(2));

    // Asegurarse de que el valor sea no negativo
    return faltante > 0 ? faltante : 0;
  },

  get_total_recargo_credito: (state, getters) => {
    // Calcular el recargo
    var recargo = state.montoCredito * (state.porcentajeRecargoCredito / 100);

    // Redondear el recargo a dos decimales
    return parseFloat(recargo.toFixed(2));
  },
  bloquear_forma_pago: (state, getters) => (descripcion) => {
    // Si hay exactamente una forma de pago seleccionada
    if (state.formasDePagoSeleccionadas.length == 1) {
      // Verificar si la forma de pago seleccionada es una de las siguientes: 'TARJETA DE CRÉDITO', 'TARJETA DE DÉBITO', 'TRANSFERENCIA' o 'MERCADO PAGO'
      if (getters.get_forma_de_pago_seleccionada('TARJETA DE CRÉDITO') || getters.get_forma_de_pago_seleccionada('TARJETA DE DÉBITO') || getters.get_forma_de_pago_seleccionada('TRANSFERENCIA') || getters.get_forma_de_pago_seleccionada('MERCADO PAGO')) {
        // Si es así, se permite
        return true
      }
    }
    // Si hay exactamente dos formas de pago seleccionadas
    if (state.formasDePagoSeleccionadas.length == 2) {
      // Verificar si la descripción de la forma de pago es 'EFECTIVO', 'VOUCHER', 'CTA. CTE.' o 'MERCADO PAGO'
      if (descripcion == 'EFECTIVO' || descripcion == 'VOUCHER' || descripcion == 'CTA. CTE.' || descripcion == 'MERCADO PAGO') {
        // Si es así, se bloquea
        return false
      }
      // Si la descripción no coincide con las formas de pago bloqueadas, se permite si una de las formas de pago seleccionadas es 'TARJETA DE CRÉDITO', 'TARJETA DE DÉBITO' o 'TRANSFERENCIA'
      if (getters.get_forma_de_pago_seleccionada('TARJETA DE CRÉDITO') || getters.get_forma_de_pago_seleccionada('TARJETA DE DÉBITO') || getters.get_forma_de_pago_seleccionada('TRANSFERENCIA')) {
        // Si es así, se permite
        return true
      }

    }
    // Si no se cumplen ninguna de las condiciones anteriores, se bloquea
    return false
  },

  get_total_descuento: (state, getters) => {
    if (state.descuento) {
      if (getters.get_monto_forma_de_pago_seleccionada('TARJETA DE CRÉDITO')) {

      } else {
        return (getters.get_subtotal_con_impuestos) * (state.descuento / 100);
      }
      // var efectivo = getters.get_monto_forma_de_pago_seleccionada('EFECTIVO') | 0
      // if (state.montoDebito){
      //   if (efectivo > 0){
      //     if (state.montoDebito + efectivo > get_subtotal_con_impuestos) {

      //     }
      //   }
      // }

      // return (state.montoDebito + efectivo) * (state.descuento / 100);
    }
    return 0
  },
  get_subtotal_con_impuestos: (state, getters) => {
    // Convertir impuestos y subtotal a números, asegurando que sean válidos
    
    var impuestos = parseFloat(state.impuestos) || 0;
    var subtotal = parseFloat(state.subtotal) || 0;
    // Calcular subtotal con impuestos
    var subtotalConImpuestos = subtotal + impuestos;
    // Devolver el subtotal con impuestos redondeado a dos decimales
    return subtotalConImpuestos.toFixed(2);
  },


  get_total: (state, getters) => {
    // Inicializar el total con el subtotal
    var total = getters.get_subtotal_con_impuestos;

    // Aplicar descuento si existe
    if (state.descuento) {
      // Calcular el total restando el descuento al subtotal
      total = parseFloat((total - getters.get_total_descuento).toFixed(2));
    }

    // Aplicar recargo por crédito si existe
    // Aplicar recargo por crédito si existe
    if (state.porcentajeRecargoCredito) {
      // Asegúrate de que total y getters.get_total_recargo_credito sean números
      const totalNumber = parseFloat(total);
      const recargoCreditoNumber = parseFloat(getters.get_total_recargo_credito);

      // Calcular el total sumando el recargo por crédito al total actual
      total = parseFloat((totalNumber + recargoCreditoNumber).toFixed(2));
    }
    console.log(total);

    // Devolver el total calculado
    return total;
  },

}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
